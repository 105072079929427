import React, { useEffect, useCallback, useRef } from 'react';
import { Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from 'react-redux'
import * as familiasActions from '../../store/familias/familias.actions';
import FamilyNav from './../../components/FamiliaNav/FamiliaNav';
import Error from './../../components/Error/Error';
import ProductList from './../../components/ProductList/ProductList';
import Spinner from './../../core/Spinner/Spinner';
import Search from '../../components/Search/Search';
import qs from 'qs';
import './Products.scss';
import { Familia } from '../../classes/familia';
import Cliente from '../../classes/cliente';

const Products: React.FunctionComponent<any> = ({ match, location }) => {
    const { filter, familia, subfamilia } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const { error, familias, fetching } = useSelector((state: any) => state.familiasStore);
    const client: Cliente = useSelector((state: any) => state.authStore.user);
    const dispatch = useDispatch();

    const fetchData = useCallback(() => {
        if (!familias.length) dispatch(familiasActions.requestFamilias());
    }, [dispatch, familias.length]);

    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
    const myRef = useRef(null);
    const executeScroll = () => scrollToRef(myRef)

    useEffect(() => {
        fetchData();
        const f: Familia = familias.find((f: Familia) => f.ID_FLIA.toString() == familia?.toString());

        const hasNoSubfamilia = f && f.SUBFAMILIAS.length === 0;

        if (filter || hasNoSubfamilia || subfamilia) {
            executeScroll();
        }
    }, [fetchData, filter, familia, subfamilia]);

    if (error) {
        return (
            <div className="ProductsError"><Error retry={fetchData}  /></div>
        );
    }

    if (fetching) {
        return (
            <div className="container ProductsContainer">
                <Spinner>Buscando productos y categorías...</Spinner>
            </div>
        );
    }

    return (
        <div className="container">
            <Helmet>
                <title>Laporta | {client ? client.NOMCLI : 'Productos'}</title>
                <meta name="description" content="Laporta distribuidor mayorista y minorista de productos " />
                <meta name="keywords" content="laporta, distribuidor, distribuidora, mayorista, rosario, supermercados, alimentos, comidas, productos" />
            </Helmet>
            
            <div className="container ProductsContainer">
                <div className="row">
                    <div className="col-md-6">
                        <div className="PedidoInstructions">
                        <div className="iconContainer"><span className="icon sale"></span></div>
                            <div>Los precios <b>son finales</b> y se muestran<br></br> con descuento (5%) ya aplicado.</div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="PedidoInstructions">
                        <div className="iconContainer"><span className="icon weight"></span></div>
                            <div>Los precios de los pesables son x kg. <br/ > Ingresar la cantidad en Hormas/Piezas</div>
                        </div>
                    </div>
                </div>
                <div className="ProductFilterContainer">
                    <Search />
                </div>
                <div className="row">
                    <div className="FamiliasContainer col-md-3">
                        <div className="FamiliaList">
                            <FamilyNav familias={familias} match={match} />
                        </div>
                    </div>
                    <div className="ProductListContainer col-md-9" ref={myRef}>
                        <Route path={`${match.path}/`} component={ProductList}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Products;