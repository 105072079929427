import React, { useState, useEffect, useRef } from 'react';
import './Checkout.scss';
import { Helmet } from "react-helmet";
import { Pedclid, Pedclim } from '../../classes/pedido';
import { useSelector, useDispatch } from 'react-redux';
import { createPedido, resetPedidoFetch } from './../../store/pedidos/pedidos.actions';
import Error from './../../components/Error/Error';
import CartItem from '../../components/CartItem/CartItem';
import Button from '../../core/Button/Button';
import Currency from '../../core/Currency/Currency';
import Spinner from '../../core/Spinner/Spinner';
import { Link } from 'react-router-dom';
import Cliente from '../../classes/cliente';
import Input from '../../core/Input/Input';

const Checkout: React.FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.authStore.user);
    const isFbUser = user && user.CODCLI == '7777';
    const client: Cliente = useSelector((state: any) => state.authStore.user);
    const { cart } = useSelector((state: any) => state.cartStore);
    const { fetching, error, requestDone } = useSelector((state: any) => state.pedidosStore);
    const [ observaciones, setObservaciones ] = useState<string>(sessionStorage.getItem('hyLaportaComments') || '');
    const [ fullname, setFullname ] = useState<string>('');
    const [ phone, setPhone ] = useState<string>('');
    const [ address, setAddress ] = useState<string>('');
    const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop);
    const myRef = useRef(null);
    const executeScroll = () => scrollToRef(myRef);
    
    useEffect(() => {
        executeScroll();
        dispatch(resetPedidoFetch());
    }, [dispatch]);

    const getSubTotal = (): number => {
        let total: number = 0;

        for (let i = 0, N = cart.length; i < N; i++) {
            const a: Pedclid = cart[i];
            const isPesable: boolean = a.COEF !== undefined && a.COEF !== 0;
            const artTotal = a.PVENTA * a.CANT_PED;
            total += isPesable? a.COEF * artTotal : artTotal;
        }

        return Number(total.toFixed(2));
    };

    const getTotal = (): number => {
        const subtotal = getSubTotal();

        if (subtotal > 1000) {
            return Number((subtotal - (subtotal * 0.05)).toFixed(2));
        }

        return subtotal;
    };

    const renderSubtotalDetails = () => {
        const subtotal = getSubTotal();
        const total = getTotal();

        if (total === subtotal) {
            return <p className="SubtotalDetails NoDiscount">NO SE HA APLICADO DESCUENTO YA QUE LA COMPRA NO SUPERA LOS $1000.-</p>
        }

        const discount = (subtotal - total).toFixed(2);
        return <p className="SubtotalDetails">DESCUENTO POR COMPRA SUPERIOR A $1000: {discount} (5%)</p>
    };

    const getDate = (): string => {
	    const d = new Date(),
		    month = d.getMonth() + 1,
		    day = d.getDate(),
		    year = d.getFullYear();
	
		return `${year}-${month}-${day}`;
    };

    const takeOrder = () => {
        const pedclids: Pedclid[] = cart.filter((c: Pedclid) => c.CANT_PED > 0);
        pedclids.forEach((p: Pedclid) => {
            p.CANT_PED = p.COEF !== undefined && p.COEF !== 0 ? p.CANT_PED * p.COEF : p.CANT_PED;
            delete p.COEF;
        });

        if (pedclids.length < 1) {
            alert('No tiene artículos en su carrito. Revise las cantidades asignadas a cada artículo.');
            return;
        }

        if (pedclids.length > 99) {
            alert('No puede pedir mas de 99 artículos. Por favor, separelo en distintos pedidos que no superen ese límite.');
            return;
        }

        const obs: string = isFbUser ? `Nombre completo: ${fullname}. Telefono: ${phone}. Direccion ${address}` : observaciones;
        const pedclim: Pedclim = new Pedclim(-1, '99', getDate(), user.CODCLI, getTotal(), obs, false, pedclids);

        sessionStorage.setItem('hyLaportaComments', '');
        dispatch(createPedido(pedclim));
        window.scrollTo(0, 0);
    };

    const setObs = (evt: any) => {
        setObservaciones(evt.target.value);
        sessionStorage.setItem('hyLaportaComments', evt.target.value.toString());
    };

    const renderCart = () => {
        if (error) {
            return <Error msg="Hubo un error al tomar su pedido. Por favor intente nuevamente" />
        }
    
        if (fetching) {
            return <Spinner>Tomando pedido, por favor espere...</Spinner>;
        }
    
        if (requestDone) {
            return <p>Muchas gracias por su pedido!</p>;
        }

        if (!cart || !cart.length) {
            return <p>No hay productos en el carrito</p>;
        }

        return (
            <div>
                <div className="CartList">
                    {cart.map((item: Pedclid, index: number) => <CartItem key={index} {...item} />)}
                </div>
                <p className="Subtotal">Subtotal: <b><Currency value={getSubTotal()} /></b></p>
                {renderSubtotalDetails()}
                <hr />
                <p className="CartTotal">TOTAL <b><Currency value={getTotal()} /></b></p>
                {isFbUser ? 
                    <div style={{ overflow: 'hidden' }}>
                        <div className="form-group FbForm">
                            <Input 
                                id='fullname'
                                type='string'
                                placeholder='Nombre completo'
                                required={true}
                                value={fullname}
                                change={(evt: any) => { setFullname(evt.target.value); }}
                                className="form-control"
                            />

                            <Input 
                                id='phone'
                                type='string'
                                placeholder='Telefono'
                                required={true}
                                value={phone}
                                change={(evt: any) => { setPhone(evt.target.value); }}
                                className="form-control"
                            />

                            <Input 
                                id='address'
                                type='string'
                                placeholder='Dirección'
                                required={true}
                                value={address}
                                change={(evt: any) => { setAddress(evt.target.value); }}
                                className="form-control"
                            />
                        </div>
                        <div className="Completar">COMPLETAR DATOS PARA PODER ENVIAR PEDIDO</div>
                    </div>
                : null}
                <div className="text-right">
                    <Link to='/productos'><Button className="SeguirComprandoBtn">SEGUIR COMPRANDO</Button></Link>
                    <Button 
                        disabled={isFbUser && (!phone || !address || !fullname)}
                        click={takeOrder} 
                        className="TomarPedidoBtn">ENVIAR PEDIDO
                    </Button>
                </div>
                {isFbUser ? null :
                <textarea 
                    placeholder="Observaciones" 
                    value={observaciones}
                    maxLength={123}
                    onChange={setObs}
                    className="Observaciones">
                </textarea>
                }
            </div>
        );
    };

    return (
        <div className="container SectionContainer Checkout">
            <Helmet>
                <title>Laporta | {client ? client.NOMCLI : 'Carrito para pedidos'}</title>
                <meta name="description" content="Laporta distribuidor mayorista y minorista de productos " />
                <meta name="keywords" content="laporta, distribuidor, distribuidora, mayorista, rosario, supermercados, alimentos, comidas, productos" />
            </Helmet>
            <h3 className="SectionTitle" ref={myRef}>Carrito de compras</h3>
            {isFbUser &&  !requestDone && cart && cart.length > 0 ? <p className="FbAclaration">Completa tus datos y toca "enviar pedido"</p> : null}
            {renderCart()}
        </div>
    );
};

export default Checkout;