import React from 'react';
import './Entregas.scss';
import { Helmet } from "react-helmet";
import Cliente from '../../classes/cliente';
import { useSelector } from 'react-redux';
const Entregas: React.FC<{}> = React.memo(() => {
    const client: Cliente = useSelector((state: any) => state.authStore.user);
    return (
        <div className="container">
            <Helmet>
                <title>Laporta | {client ? client.NOMCLI : 'Días de entregas'}</title>
                <meta name="description" content="Laporta distribuidor mayorista y minorista de productos " />
                <meta name="keywords" content="laporta, distribuidor, distribuidora, mayorista, rosario, supermercados, alimentos, comidas, productos" />
            </Helmet>
            <h3 className="SectionTitle">Días de entrega</h3>
            <p>En el siguiente mapa puede visualizar su zona y los días <br /> en los que estaremos efectuando nuestro reparto según la misma.</p>
            <iframe
                title="Entregas"
                src="https://www.google.com/maps/d/u/0/embed?mid=1wLj_XifEu5h5A4IQY4hdOnRPiW4vuREv" 
                width="100%"
                height="480">
            </iframe>
        </div>
    );
});

export default Entregas;