import React from 'react';
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';
import Cliente from '../../classes/cliente';
import Slider from './../../components/Slider/Slider';
import './Home.scss';
const Home: React.FunctionComponent<{}> = React.memo(() => {
    const client: Cliente = useSelector((state: any) => state.authStore.user);
    return (
        <div>
            <Helmet>
                <title>Laporta | {client ? client.NOMCLI : 'Tienda Oficial'}</title>
                <meta name="description" content="Laporta distribuidor mayorista y minorista de productos " />
                <meta name="keywords" content="laporta, distribuidor, distribuidora, mayorista, rosario, supermercados, alimentos, comidas, productos" />
            </Helmet>
            <Slider />
            <div className="Home container">
                <div className="row">
                    <div className="col-md-6 PresentationContainer">
                        <div className="HighlightSection">
                            <div className="iconContainer"><span className="icon sale"></span></div>
                            <div className="float-left">
                                <h4>5% DE DESCUENTO</h4>
                                <p>EN COMPRAS SUPERIORES A $1000.</p>
                            </div>
                        </div>
                        <div className="HighlightSection">
                            <div className="iconContainer"><span className="icon truck"></span></div>
                            <h4>ENVÍO GRATIS</h4>
                            <p>EN COMPRAS SUPERIORES A $500.</p>
                        </div>
                        <h3 className="SectionTitle">Conocenos</h3>
                        <p className="MainText">SEGUIMOS INNOVANDO: CON NUESTRA PÁGINA PODÉS HACER LOS PEDIDOS PARA TU NEGOCIO O TU HOGAR SIMPLEMENTE DESDE TU TELÉFONO O COMPUTADORA.</p>
                    </div>
                    <div className="col-md-6">
                        <img src="./assets/marcas.jpg" alt="Marcas con las que trabajamos. Laprota" />
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Home;