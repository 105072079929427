import React from 'react';
import { Familia, Subfamilia } from './../../classes/familia';
import './FamiliaNav.scss';
import { Link } from 'react-router-dom';

const FamiliaNav: React.FunctionComponent<{ familias: Familia[], match: any }> = React.memo(({ familias = [], match }) => {
    const renderSubfamilias = (familia: Familia, collapse: string, heading: string) => {
        return (
            <ul
                aria-labelledby={heading}
                className='collapse'
                data-parent="#FamiliaNav"
                id={collapse}>
                {familia.SUBFAMILIAS.map((s: Subfamilia, index: number) => (
                    <li
                        className="collapsed SubfamiliaItem"
                        key={index}>
                        <Link to={`${match.url}?familia=${familia.ID_FLIA}&subfamilia=${s.ID_SUBF}`}>
                            <button 
                                aria-controls={collapse}
                                aria-expanded="false" 
                                className="card-header collapsed SubfamiliaBtn"
                                id={heading}>{s.NSUBF}
                            </button>
                        </Link>
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <ul 
            className="FamiliasList accordion" 
            id="FamiliaNav">
            {familias.map((familia: Familia, index: number) => {
                const heading: string = `heading${index}`;
                const collapse: string = `collapse${index}`;

                return (
                    <li 
                        className={`card FamiliaItem ${familia.ID_FLIA === 9 ? 'ofertas' : null}`}
                        key={index}>
                        <Link to={`${match.url}?familia=${familia.ID_FLIA}`}>
                            <button 
                                aria-controls={collapse}
                                aria-expanded="false" 
                                className="card-header collapsed FamiliaBtn"
                                data-target={`#${collapse}`} 
                                data-toggle="collapse" 
                                id={heading}>
                                {familia.NFAMILIA} {familia.SUBFAMILIAS.length ? <span className="ExpandSubfamilias">+</span> : null}
                            </button>
                        </Link>
                        {renderSubfamilias(familia, collapse, heading)}
                    </li>
                );
            })}
        </ul>
    );
});

export default FamiliaNav;