import React from 'react';
import NavList, { NavListItemMap } from '../NavList/NavList';
import { useSelector } from 'react-redux';
import './AuthNav.scss';

const AuthNav: React.FunctionComponent<{}> = React.memo(() => {
    const user = useSelector((state: any) => state.authStore.user);
    const authenticated: boolean = user !== null;
    const isFbUser = user && user.CODCLI == '7777';
    const navItems: NavListItemMap[] = [];
    const instructions: NavListItemMap[] = [{
        activeClassName: 'active-route', className: '', exact: true, label: 'Instrucciones', to: '/instrucciones' 
    }]

    if (authenticated) {
        navItems.push({ activeClassName: 'active-route', className: '', exact: true, label: 'Días de entrega', to: '/dias-de-entrega' });
        if (!isFbUser) navItems.push({ activeClassName: 'active-route', className: '', exact: true, label: 'Mis pedidos', to: '/mis-pedidos' });
        navItems.push(
            { activeClassName: 'active-route', className: '', exact: true, label: 'Carrito', to: '/checkout', icon: 'cart' },
            { activeClassName: 'active-route', className: '', exact: true, label: 'Salir', to: '/logout' }
        );
    } else {
        navItems.push(
            { activeClassName: 'active-route', className: '', exact: true, label: 'Ingresar', to: '/ingresar' }            
        );
    }

    return (
        <div className="AuthNavContainer">
            <div className="container">
                <NavList items={instructions} className="AuthNavLinks instructionsUl " />
                <NavList items={navItems} className="AuthNavLinks" />
            </div>
        </div>
    );
});

export default AuthNav;