import React from 'react';
import { Pedclid } from '../../classes/pedido';
import { useDispatch } from 'react-redux';
import { removeFromCart } from './../../store/cart/cart.actions';
import QtySelector from '../QtySelector/QtySelector';
import Currency from '../../core/Currency/Currency';
import './CartItem.scss';
import Input from '../../core/Input/Input';

const CartItem: React.FC<Pedclid> = ({ PRECIO_UNITARIO, CANT_PED, DESART, CODART, COEF }) => {
    const dispatch = useDispatch();
    const remove = () => {
        dispatch(removeFromCart(CODART));
        window.location.reload();
    };
    const isPesable: boolean = COEF !== undefined && COEF !== 0;
    const totalItem: number = isPesable ? (+CANT_PED * +COEF) * +PRECIO_UNITARIO : +PRECIO_UNITARIO * +CANT_PED;

    return (
        <div className="CartItemContainer">
            <div className="ItemName">{DESART}</div>
            <div className="QtyHandler">
                <QtySelector 
                    desart={DESART}
                    codart={CODART} 
                    isCheckout={true}
                    price={PRECIO_UNITARIO}
                    coef={COEF}
                /> {isPesable ? null : ' x '}
                <Currency className="highlight" value={PRECIO_UNITARIO} /> 
                {isPesable ? <div className="QtySelector"><span> x </span> 
                    <Input 
                        id={`${CODART}-pesable`}
                        className="QtyInput"
                        disabled={true}
                        type="number" 
                        value={CANT_PED * COEF}
                    /> <span> Kg.</span>
                </div> : null }
                = <Currency className="TotalItem" value={Number(totalItem.toFixed(2))} />
                <span 
                    className="removeIcon"
                    onClick={remove}>
                </span>
            </div>
        </div>
    );
};

export default CartItem;