import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cliente from '../../classes/cliente';
import './WpBubble.scss';

const WpBubble: React.FC<{}> = React.memo(() => {
    const { cart } = useSelector((state: any) => state.cartStore);
    const client: Cliente = useSelector((state: any) => state.authStore.user);
    const history = useHistory();

    const goToCart = () => {
        history.push('/checkout');
    };

    if (!!client) {
        return (
            <div
                onClick={goToCart} 
                className="WpBubble">
                <span className="WpIcon"></span>
                <div className="cartQty">{cart.length}</div>
            </div>
        );
    }

    return <div></div>
});

export default WpBubble;