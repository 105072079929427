import React from 'react';
import './Footer.scss';

const Footer: React.FunctionComponent<{}> = React.memo(() => {
    const goToWp = () => {
        window.location.href = 'https://wa.me/5493415619344';
    };

    return (
        <footer className="FooterContainer">
            <div className="MainFooterContainer">
                <div className="container">
                    <div className="col-md-12 text-center">
                        <p className="VentasConsultas">VENTAS Y CONSULTAS</p>
                        <p onClick={goToWp} className="Number">
                        <p>341 561-9344</p>
                        <img src="./assets/wp.png" className="wpBtn"/>
                        </p>
                        <p>
                            <a href="mailto:laportaventas@gmail.com"><b>laportaventas</b>@gmail.com</a>
                        </p>
                    </div>
                </div>
            </div>
            <div className="SubfooterContainer">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-sm-6">LAPORTA DISTRIBUIDOR | 2021</div>
                        <div className="col-md-6 col-sm-6 PowerBy">
                            <a 
                                href="http://huskysoftware.com.ar" 
                                target="_blank"
                                rel="noopener noreferrer">Desarrollo <span className="husky-logo"></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
});

export default Footer;
