import React from 'react';
import './Slider.scss';
import Button from '../../core/Button/Button';
import { Link } from 'react-router-dom';

const Slider: React.FunctionComponent<{}> = React.memo(() => (
    <div>
        <div 
            id="magSliderControls" 
            className="Slider carousel slide" 
            data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img 
                        className="d-block w-100" 
                        src="./assets/slider/1.jpg" 
                        alt="Laporta"
                    />
                    <div className="container">
                        <div className="carousel-caption d-md-block SliderCaption">
                            <h1>ABASTECER TU NEGOCIO <br /> ES MUCHO MÁS FÁCIL</h1>
                            <p>Armá tu pedido en nuestra tienda online.</p>
                        </div>
                    </div>
                </div>
            
                <div className="carousel-item">
                    <img 
                        className="d-block w-100" 
                        src="./assets/slider/2.jpg" 
                        alt="Laporta"
                    />
                    <div className="carousel-caption d-md-block SliderCaption">
                        <h1 className="CompraMensual">HACÉ LA COMPRA MENSUAL <br /> SIN MOVERTE DE CASA</h1>
                    </div>
                </div>
            
                <div className="carousel-item">
                    <img 
                        className="d-block w-100" 
                        src="./assets/slider/3.jpg" 
                        alt="Laporta"
                    />
                    <div className="carousel-caption d-md-block SliderCaption">
                        <h1>NO PIERDAS DINERO. <br /> MANTENÉ TODOS LOS <br /> PRECIOS ACTUALIZADOS</h1>
                    </div>
                </div>

                <div className="carousel-item">
                    <img 
                        className="d-block w-100" 
                        src="http://www.mcsiles.com.ar/usuarios/laporta/SLIDER.JPG" 
                        alt="Laporta"
                    />
                    <div className="carousel-caption d-md-block SliderCaption">
                        <h1 className="Descubri">DESCUBRÍ LA OFERTA <br /> SEMANAL EXCLUSIVA <br /> DE NUESTRA TIENDA</h1>
                        <Link to={'/productos?familia=9'}><Button>DESCUBRIR AHORA!</Button></Link>
                    </div>
                </div>
            </div>
    
            <a 
                className="carousel-control-prev" 
                href="#magSliderControls" 
                role="button" 
                data-slide="prev">
                <span 
                    className="carousel-control-prev-icon" 
                    aria-hidden="true">
                </span>
                <span className="sr-only">Anterior</span>
            </a>
            <a 
                className="carousel-control-next" 
                href="#magSliderControls" 
                role="button" 
                data-slide="next">
                <span 
                    className="carousel-control-next-icon" 
                    aria-hidden="true">
                </span>
                <span className="sr-only">Siguiente</span>
            </a>
        </div>
    </div>
));

export default Slider;