import React, { useEffect, useCallback } from 'react';
import * as authActions from '../../store/auth/auth.actions';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { sendContact, resetContactState } from './../../store/contact/contact.actions';
import Error from './../../components/Error/Error';
import Form, { FormElementMap } from './../../components/Form/Form';
import Spinner from './../../core/Spinner/Spinner';
import { Helmet } from "react-helmet";
import qs from 'qs';
import Cliente from '../../classes/cliente';

const Auth: React.FunctionComponent<any> = ({ match = {}, location}) => {
    const dispatch = useDispatch();
    const { fb } = qs.parse(location.search, { ignoreQueryPrefix: true });
    const comesFromFb = fb === 'true';
    const history = useHistory();
    const client: Cliente = useSelector((state: any) => state.authStore.user);
    const { error, fetching, msgSent } = useSelector((state: any) => state.contactStore);
    const state = useSelector((state: any) => state.authStore);
    const login: boolean = match.path === '/ingresar';
    const resetAuthState = useCallback(() => { dispatch(authActions.resetAuthState()); dispatch(resetContactState()); }, [dispatch]);
    
    const onSubmit = (credentials: any) => {
        if (login) {
            dispatch(authActions.authenticate({ credentials, loginAction: login }));
        } else {
            dispatch(sendContact({
                name: credentials.NOMCLI,
                tel: credentials.TELCLI,
                domicilio: credentials.ADDRESS,
                message: 'Solicitud de codigo y nombre de usuario para registro de la web.'
            }));
        }
    };

    useEffect(() => { 
        window.scrollTo(0, 0);
        if (comesFromFb) {
            if (state.user === null) {
                dispatch(authActions.authenticate({ credentials: {
                    NOMCLI: 'anonimo',
                    CODCLI: 7777
                }, loginAction: login }));
            }
        } else {
            resetAuthState(); 
        }

    }, [resetAuthState, login]);

    
 
    const renderForm = () => {
        const formElements: FormElementMap[] = [];

        if (login) {
            formElements.push({ 
                elementType: 'input', 
                id: 'NOMCLI', 
                placeholder: 'Usuario', 
                valueType: 'text', 
                required: true
            }, {
                elementType: 'input', 
                id: 'CODCLI', 
                placeholder: 'Contraseña', 
                valueType: 'password', 
                required: true
            });
        } else {
            formElements.push({
                elementType: 'input', 
                id: 'NOMCLI', 
                placeholder: 'Nombre y Apellido', 
                valueType: 'text', 
                required: true
            }, {
                elementType: 'input', 
                id: 'TELCLI', 
                placeholder: 'Teléfono', 
                valueType: 'text', 
                required: true
            }, {
                elementType: 'input', 
                id: 'ADDRESS', 
                placeholder: 'Domicilio', 
                valueType: 'string'
            })
        }

        if (error) {
            return <Error retry={resetAuthState} />;
        }

        if (fetching) {
            return <Spinner>Enviando solicitud de registro...</Spinner>;
        }

        if (comesFromFb) {
            return <Spinner>Aguarde un momento...</Spinner>;
        }

        if (msgSent) {
            return <p>Gracias por comunicarse con nosotros! Le responderemos a la brevedad.</p>
        }
        
        return (
            <>
                <Form 
                    onSubmit={onSubmit}
                    elements={formElements}
                    name="authForm"
                    submitText={login ? 'Ingresar' : 'Registrarme'}
                />
                <p><Link to='/contacto'>Olvidó su contraseña? Contáctese con nosotros para recuperarla.</Link></p>
                {login ? <p><Link to='/registrarme'>No tiene usuario? Haga click aquí.</Link></p> : null}
            </>
        );
    };

    const renderAuthView = () => {
        const { fetchError, fetching, invalidReason } = state;

        if (fetching) {
            return <Spinner>Validando credenciales...</Spinner>;
        }

        if (fetchError) {
            return <Error retry={resetAuthState} />;
        }

        return (
            <div className="AuthFormContainer">
                {renderForm()}
                {invalidReason !== '' ? <p className="ErrorMsg">{invalidReason}</p> : null}
            </div>
        );
    };

    if (state.user !== null) {
        if (state.user.CODCLI == "7777") history.push('/productos?familia=9');
        else history.push('/productos');
    }
    
    return (
        <div className="container SectionContainer">
            <Helmet>
                <title>Laporta | {client ? client.NOMCLI : 'Ingreso de usuarios'}</title>
                <meta name="description" content="Laporta distribuidor mayorista y minorista de productos " />
                <meta name="keywords" content="laporta, distribuidor, distribuidora, mayorista, rosario, supermercados, alimentos, comidas, productos" />
            </Helmet>
            <h3 className="SectionTitle">{login ? 'Ingresar' : 'Registrarme'}</h3>
            {renderAuthView()}
        </div>
    );
};

export default Auth;