import React from 'react';
import QtySelector from '../QtySelector/QtySelector';
import Currency from '../../core/Currency/Currency';
import './ProductItem.scss';
import Button from '../../core/Button/Button';
import { useHistory } from 'react-router-dom';

export interface IProductItem {
    codart: string,
    desart: string,
    familia?: string,
    subfamilia?: string,
    price: number,
    imgUrl?: string,
    showPrice: boolean,
    coef: number,
    idflia: number
};

const ProductItem: React.FC<IProductItem> = (articulo: IProductItem) => {
    const { codart, desart, coef, familia, subfamilia, price, imgUrl, showPrice, idflia } = articulo;

    const priceWithDiscount: number = Number((price - (price * 0.05)).toFixed(2));
    const sinStock: boolean = desart.substring(0, 3).toLowerCase() === 'ss ';
    const history = useHistory();

    let soloEncargo: boolean;

    let desartToShow: string = desart;

    if (sinStock) {
        desartToShow = desart.slice(3, desart.length);
    } else {
        soloEncargo = desart.substring(0, 5).toLowerCase() === 'ss/e ';

        if (soloEncargo) {
            desartToShow = desart.slice(5, desart.length);
        }
    }

    const goToLogin = () => {
        history.push('/ingresar');
    };

    const renderPriceAndCart = () => {
        if (sinStock) {
            return <div className="SinStock">SIN STOCK</div>
        }

        if (soloEncargo) {
            return <div className="SinStock">POR ENCARGUE</div>
        }

        if (showPrice) {
            const isPesable: boolean = coef !== undefined && coef !== 0;
            return (
                <>
                    <p className="Price">
                        <Currency value={priceWithDiscount} /> 
                        <span> {isPesable ? 'x Kg.' : 'x Unid.'}</span> 
                        <span className="icon sale"></span>
                    </p>
                    <div className="QtyCell">
                        <QtySelector 
                            desart={desart}
                            codart={codart} 
                            price={price}
                            isCheckout={false}
                            addOnEnter={true}
                            coef={coef}
                        /> <span>{isPesable ? `Pieza x ${coef} Kg.` : ' Unidades'}</span>
                    </div>
                </>
            );
        }

        return (
            <p className="text-center">
                <Button 
                    click={goToLogin}
                    className="AddBtn">VER PRECIO
                </Button>
            </p>
        );
    };

    return (
        <div className="col-lg-4 col-md-6 ">
            <div className={`ProductItem ${idflia === 9 ? 'productInOferta' : null}`}>
                <div className="ImgContainer">
                    <img 
                        alt={desart}
                        className={!imgUrl ? 'notAvailable' : ''}
                        src={imgUrl ? `http://www.mcsiles.com.ar/usuarios/laporta/${imgUrl}` : './assets/no-disponible.jpg'} 
                    />
                </div>
                <div className="ContentContainer">
                    <h3 className="Desart">{desartToShow.toUpperCase()}</h3>
                    <p className="Categorias">{familia || '-'} {subfamilia ? `> ${subfamilia}` : null}</p>
                    {renderPriceAndCart()}
                </div>
            </div>
        </div>
    );
};

export default ProductItem;