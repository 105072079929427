import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { resetAuthState } from './../../../store/auth/auth.actions';
import { Redirect } from 'react-router-dom';

const Logout: React.FunctionComponent<{}> = () => {
    const dispatch = useDispatch();

    useEffect(() => { dispatch(resetAuthState()); }, [dispatch]);

    localStorage.removeItem('laportaMagCart');

    return <Redirect to='/' />
}

export default Logout;