import React from 'react';
import './Instructions.scss';
import { Helmet } from "react-helmet";
import Cliente from '../../classes/cliente';
import { useSelector } from 'react-redux';
const Instructions: React.FC<{}> = React.memo(() => {
    const client: Cliente = useSelector((state: any) => state.authStore.user);
    return (
        <div className="container Devoluciones">
            <Helmet>
            <title>Laporta | {client ? client.NOMCLI : 'Instrucciones'}</title>
                <meta name="description" content="Laporta distribuidor mayorista y minorista de productos " />
                <meta name="keywords" content="laporta, distribuidor, distribuidora, mayorista, rosario, supermercados, alimentos, comidas, productos" />
            </Helmet>
            <h3 className="SectionTitle">Instrucciones</h3>
            <h4 className="SubTitle">Pasos para hacer un pedido</h4>
            <div className="separator"></div>
            <ol style={ { paddingLeft: '15px' } }>
                <li>Ir a "Ingresar" <span className="instructionsImgs ingresar"></span> y escriba su "usuario" y "contraseña".</li>
                <li>Selecciona los artículos que necesites</li>
                <li>Ir al carrito <span className="instructionsImgs cart"></span>, vas a ver tu pedido y podes agregar un comentario o modificar las cantidades.</li>
                <li>Tocar "Enviar pedido" <span className="instructionsImgs sendPedido"></span></li>
            </ol>

            <h4 className="SubTitle">Precios</h4>
            <div className="separator"></div>
            <ul>
                <li>Los precios que se muestran son <b>FINALES</b> y tienen el descuento por volumen (5%) ya aplicado.</li>
                <li>En el "Carrito", el descuento por volumen (5%) lo vas a ver en el total. <span className="instructionsImgs discount"></span></li>
            </ul>

            <h4 className="SubTitle">Artículos pesables</h4>
            <div className="separator"></div>
            <p>Son los que muestran el logo <span className="instructionsImgs weight"></span> en la foto y el precio es x kilo. Para pedir debo anotar la cantidad de piezas que describe el título del producto.</p>

            <h4 className="SubTitle">Entre parentesis</h4>
            <div className="separator"></div>
            <p>En la descripción de los artículos, entre paréntesis, solo informa la cantidad que trae
                una caja.</p>

            <h4 className="SubTitle">Mis pedidos</h4>
            <div className="separator"></div>
            <p>En ese botón vas a ver todos los pedidos que hiciste en la tienda, sirve para recordar las cosas
    que compramos y para confirmar que la distribuidora recibio el pedido.</p>
        </div>
    );
});

export default Instructions;